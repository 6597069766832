:root {
    --copy-button-background: #616161;
}


.demo_preview {
    line-height: 1.5; /* Match odj.cloud */
}

.demo-copy-code-button,
.demo_code .demo,
.demo_preview,
.demo_button,
.demo_toggle,
.demo_resize {
    box-sizing: border-box;
}

/* Docsify themeable */
:root {
    --code-block-border-radius: 0;
    --code-block-margin: 0;
}

/* Sets up button */
.demo-copy-code-button {
    position: absolute;
    bottom: 4px;
    right: 4px;
    text-transform: uppercase;
    padding: 8px;
    user-select: none;
    color: white;
    background: var(--copy-button-background);
    border: 0;
    border-radius: 4px;
    cursor: pointer;
    opacity: 0;
    transition: 350ms opacity;
}

pre:hover .demo-copy-code-button, .demo-copy-code-button:focus-visible {
    opacity: 1;
}

.demo-copy-code-button.copied {
    animation: pulse 0.5s;
    --pulse-color: rgb(var(--odj-input-background));
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 var(--odj-input-border-color);
    }
    70% {
        box-shadow: 0 0 0 4px transparent;
    }
    100% {
        box-shadow: 0 0 0 0 transparent;
    }
}

/* Improves code defaults */
code {
    font-size: 14px;
    padding: 16px;
}

/* Controls code */
.demo_code pre {
    margin: 0;
    max-width: 100%;
    border: solid 1px var(--odj-input-border-color);
}

.demo_preview_content {
    width: 100%;
    min-height: 3rem;
}

.demo_preview_content > :first-child {
    margin-top: 0;
}


/* Style for demo container */
.demo {
    overflow: visible;
    box-sizing: border-box;
    border-top-right-radius: 3px;
    position: relative;
    background: var(--odj-background-color);
    min-width: 20rem;
    margin-bottom: 1.5rem;
}

.demo_preview {
    padding: 1rem;
    border: solid 1px var(--odj-input-border-color);
    border-bottom: none;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    background: var(--odj-background-color);
    position: relative;
    min-width: 20rem;
    min-height: 5rem;
    display: flex;
    align-items: center;
    max-width: 100%;
}

/* For smaller screens */
@media screen and (max-width: 600px) {
    .demo_preview {
        border-right: solid 1px var(--odj-input-border-color);
        border-top-right-radius: 3px;
        padding-right: 1.5rem;
    }
}
